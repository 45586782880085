import React, { useState } from 'react'
import './styles/member.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegWindowClose } from 'react-icons/fa';
import { useNavi } from '../context/NavigationContext';


const JoinMember = (customer) => {

  const [formInput, setFormInput] = useState();
  const navigate = useNavigate();
  const { origin } = useNavi();

  const handleExit = () => {
    navigate(origin);  
  };

  return (
    <div className='membership-modal-container full-size' style={{display: 'block'}}>
      <div className='flex-container'>
      
        <div className='c-membership-modal full-size'>
          <div className='c-mm-success account-form'style={{display: 'none'}}></div>
          <div className='c-mm-info account-form 'style={{display: 'none'}}></div>
          <div className='c-mm-login account-form'style={{display: 'none'}}>
            <div className='form-container'>
              <h2>Welcome Back</h2>
              <p>Log in to activate special member pricing. 
              </p>
              <form className='metafields_form' action="">
                <input type="hidden" name='{customer[id]}' value=''/>
                <input 
                  type='email' 
                  value={formInput}
                  size={30}
                  placeholder='Email Address' 
                  required        
                />
                <span className='email-error-container error-container'></span>
                <input 
                  type='password' 
                  value={formInput}
                  size={30}
                  placeholder='Password' 
                  required        
                />
                <input type="hidden" value={formInput} 
                />
                <span className='password-error-container error-container'></span>
                <button type='submit' value='Sign In' className='modal-submit'>
                  <span className='modal-submit-button-text'>Submit</span>
                </button>
              </form>
              <h4 >
                <a className='c-mm-forgot-password-btn' href="#">FORGOT MY PASSWORD</a>
              </h4>
              <h4 className='c-mm-signup-btn'>
                &nbsp;NOT A MEMBER?&nbsp;
                <span>JOIN TODAY</span>
              </h4>
            </div>
          </div>
          <div className='c-mm-signup account-form'style={{display: 'block'}}>
            <div className='form-container'>
              <h2>BECOME A MEMBER</h2>
              <p>Don't slow down now! Exclusvie pricing, early releases, an inspirational newletter and much more. 
              </p>
              <form className='metafields_form' action="">
                <input type="hidden" name='{customer[id]}' value={formInput}/>
                <input 
                  type='text' 
                  value={formInput}
                  size={30}
                  placeholder='First Name' 
                  required        
                />
                <span className='first-name-error-container error-container'></span>
                <input 
                  type='email' 
                  value={formInput}
                  size={30}
                  placeholder='Email Address' 
                  required        
                />
                <span className='email-error-container error-container'></span>
                <input 
                  type='password' 
                  value={formInput}
                  size={30}
                  placeholder='Password' 
                  required        
                />
                <input type="hidden" value={formInput} 
                />
                <span className='password-error-container error-container'></span>
                <button type='submit' value='Sign In' className='modal-submit'>
                  <span className='modal-submit-button-text'>Submit</span>
                </button>
                <h4 className='c-mm-login-btn'>
                &nbsp;ALREADY A MEMBER?&nbsp;
                  <a href='/login' state={{ from: origin }}>
                    <span>LOG IN</span>
                  </a>
                </h4>
              </form>
              <form className='metafields_form_real hide' action="">
                <input 
                  type="hidden" 
                  value=''
                />
              </form>
              <p>
                <i>By submitting this form, you agree to receive emails from Framed by Create. You can opt-out at any time.     
                </i>
              </p>
            </div>
          </div>
          <div className='c-mm-forgot-password account-form'style={{display: 'none'}}>
            <div className='flex-container'>
              <form method='post' action='/account/recover' acceptCharset='UTF-8'>
                <h2>Forgot Password</h2>
                <p>Enter your email address below and we'll follow up with a link to reset your password.
                </p>
                <input 
                  type='email' 
                  value={formInput}
                  size={30}
                  placeholder='Email Address' 
                  required        
                />        
                <button type='submit' value='Submit' className='modal-submit'>
                  <span className='modal-submit-button-text'>Submit</span>
                </button>
              </form>
            </div>
          </div>
          <div className='c-mm-right full-size member'>
            <span className='modal-x'>
              <button className='list-close' onClick={handleExit}>
                <FaRegWindowClose />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinMember