import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/shop-prints.css'
import { FaRegWindowClose } from 'react-icons/fa';

const categories = [
  { name: "Shop All", url: "/shop/all-prints/", image: "/assets/images/215SKYLINE-BW.jpg" },
  { name: "New Arrivals", url: "/shop/all-prints/", image: "/assets/images/215SKYLINE-BW.jpg" },
  { name: "B&W", url: "/shop/all-prints?category=black+%26+white", image: "/assets/images/215SKYLINE-BW.jpg" },
  { name: "Travel", url: "/shop/all-prints?category=travel", image: "/assets/images/215SKYLINE-BW.jpg" },
  { name: "Nature", url: "/shop/all-prints?category=nature", image: "/assets/images/215SKYLINE-BW.jpg" },
  { name: "City", url: "/shop/all-prints?category=city", image: "/assets/images/215SKYLINE-BW.jpg" },

];

const ShopPrintsPage = () => {
  const [selectedImage, setSelectedImage] = useState(categories[0].image);
  const navigate = useNavigate();
  const handleExit = () => {
    navigate(-1); // Use history.goBack() to return to the previous page
  };
  
  const handleCategoryHover = (image) => {
    setSelectedImage(image);
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
  <div className="shop-list" style={{ width: '540px', overflowY: 'auto' }}>
    <div>
      <span className="modal-x-nav">
        <button className="list-close js-menu-shop-close" onClick={handleExit}>
          <FaRegWindowClose />
        </button>
      </span>
    </div>
    <ul className="shop-prints-navigation">
      {categories.map((category) => (
        <li key={category.name} onMouseOver={() => handleCategoryHover(category.image)}>
          <a href={category.url} className="nav-link">
            {category.name}
          </a>
        </li>
      ))}
    </ul>
  </div>
  <div className="shop-image-container">
    <img src={selectedImage} alt="Selected" className="shop-image" loading="lazy"/>
  </div>
</div>

  );
};

export default ShopPrintsPage;
