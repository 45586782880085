import React, { useState } from 'react';
import './styles/login.css';
import { login } from '../redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegWindowClose } from 'react-icons/fa';
import { useNavi } from '../context/NavigationContext';


const Login = (customer) => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isFetching, error} = useSelector((state) => state.user);
  const { origin } = useNavi();



  const [formInput, setFormInput] = useState();

  const handleLogin = (e) => {
    e.preventDefault();
    login(dispatch, {email, password})
  };

  const handleExit = () => {
    navigate(origin); 
  };

  console.log(origin)
  return (
    <div className='membership-modal-container' style={{display: 'block'}}>
      <div className='flex-container'>
        <div className='c-membership-modal'>
          <div className='c-mm-success account-form'style={{display: 'none'}}></div>
          <div className='c-mm-info account-form 'style={{display: 'none'}}></div>
          <div className='c-mm-login account-form'style={{display: 'block'}}>
            <div className='form-container'>
              <h2>Welcome Back</h2>
              <p>Log in to activate special member pricing. 
              </p>
              <form className='metafields_form' action="">
                <input type="hidden" name='{customer[id]}' value=''/>
                <input 
                  type='email' 
                  value={formInput}
                  size={30}
                  placeholder='Email Address' 
                  onChange={(e)=>setEmail(e.target.value)}
                  required        
                />
                 <input 
                  type='password' 
                  value={formInput}
                  size={30}
                  placeholder='Password' 
                  onChange={(e)=>setPassword(e.target.value)}
                  required        
                />
                <input type="hidden" value={formInput}  
                />
                {error ? <span className='password-error-container error-container'>Invalid Credintials</span> : ""}
                <button type='submit' value='Sign In' className='modal-submit' disabled={isFetching} onClick={handleLogin}>
                  <span className='modal-submit-button-text'>Submit</span>
                </button>
                <h4 >
                  <a className='c-mm-forgot-password-btn' href="#">FORGOT MY PASSWORD</a>
                </h4>
                <h4 className='c-mm-signup-btn'>
                &nbsp;NOT A MEMBER?&nbsp;
                  <a href="/register" state={{ from: origin }}>
                    <span>JOIN TODAY</span>
                  </a>
                </h4>
              </form>
              <form className='metafields_form_real hide' action="">
                <input 
                  type="hidden" 
                  value=''
                />
              </form>
            </div>
          </div>
          <div className='c-mm-signup account-form'style={{display: 'none'}}></div>
          <div className='c-mm-forgot account-form'style={{display: 'none'}}>
            <div className='form-container'>
              <h2>Forgot Password</h2>
              <p>Enter your email address below and we'll follow up with a link to reset your password.
              </p>
              <input 
                type='email' 
                value={formInput}
                size={30}
                placeholder='Email Address' 
                required        
              />        
              <button type='submit' value='Submit' className='modal-submit'>
                <span className='modal-submit-button-text'>Submit</span>
              </button>
              <h4 >
                <a className='c-mm-forgot-password-btn' href="#">FORGOT MY PASSWORD</a>
              </h4>
              <h4 className='c-mm-signup-btn'>
              &nbsp;NOT A MEMBER?&nbsp;
                <span>JOIN TODAY</span>
              </h4>
              <form className='metafields_form_real hide' action="">
                <input 
                  type="hidden" 
                  value=''
                />
              </form>
            </div>
          </div>
          <div className='c-mm-right login'>
            <span className='modal-x'>
              <button className='list-close' onClick={handleExit}>
                <FaRegWindowClose />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login