import Cart from "./pages/Cart";
import Home from "./pages/Home";
import Member from "./pages/Member";
import Login from "./pages/Login";
import Product from "./pages/Product";
import ProductList from "./pages/ProductList"; 
import ShopPrintsPage from "./pages/ShopPrints";
import Profile from "./pages/Profile";

import React, { useEffect } from "react";
import {BrowserRouter as Router, Route, Routes, redirect, useNavigate, Navigate} from 'react-router-dom';
import Success from "./pages/Success";
import { useSelector } from "react-redux";
import { NavbarProvider } from './components/NavbarContext';
import FrameProvider from "./components/FrameContext";
import { NavigationProvider } from './context/NavigationContext';
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import ReturnPolicy from "./pages/ReturnPolicy";


function App() {
  const user = useSelector(state => state.user.currentUser);
  return (
    <NavbarProvider>
      <FrameProvider>
        <Router>
          <NavigationProvider>
            <Routes>
              <Route path='/' element={<Home />} /> 
              <Route path='/shop/all-prints/' element={<ProductList />} /> 
              <Route path="/shop-prints" element={<ShopPrintsPage/>}/>

              <Route path='/product/:id' element={<Product />} /> 
              <Route path='/cart' element={<Cart />} />
              <Route 
                path='/login' 
                element={user ? <Navigate replace to='/'/> : <Login />}
              /> 
              <Route path='/register' element={<Member />} />
              <Route path='/success' element={<Success />} />

              <Route path="/pages/settings" element={<Profile />} />

              <Route path="/policies/terms-of-service" element={<Terms />}/>
              <Route path="/policies/privacy" element={<Privacy />}/>
              <Route path="/policies/returns" element={<ReturnPolicy />}/>
            </Routes>
          </NavigationProvider>
        </Router>
      </FrameProvider>
    </NavbarProvider>
  );
}

export default App;
